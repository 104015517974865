import "@fontsource/open-sans/700.css"
import "@fontsource/open-sans/400.css"

import React from "react";
//import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";

//import Fonts from "./src/utils/fonts";
import customTheme from "./src/utils/theme";

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
    console.log("new pathname", location.pathname)
    console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}

// TODO: Find a use for wrapRootElement (maybe routing?) 
// Allow a plugin to wrap the root element
// export const wrapRootElement = ({ element, props }) => {
//     return (
//         <ChakraProvider {...props} theme={customTheme}>
//             <Fonts />
//             {element}
//         </ChakraProvider>
//     )
// }

// export const replaceHydrateFunction = () => {
//     return (element, container, callback) => {
//         console.log("HYDRATE");
//         console.log(element);
//         console.log(container);
//         console.log(callback);
//         ReactDOM.render(element, container, callback);
//     };
// };  

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
    return (
        <ChakraProvider {...props} theme={customTheme}>
            {element}
        </ChakraProvider>
    )
}
