// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-category-view-js": () => import("./../../../src/components/category_view.js" /* webpackChunkName: "component---src-components-category-view-js" */),
  "component---src-components-product-view-js": () => import("./../../../src/components/product_view.js" /* webpackChunkName: "component---src-components-product-view-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-customer-js": () => import("./../../../src/pages/customer/[...].js" /* webpackChunkName: "component---src-pages-customer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

