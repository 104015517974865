import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
    fonts: {
        heading: 'Open Sans',
        body: 'Open Sans',  
        mono: 'Open Sans',
        // heading: 'Heading Font Name',
        // body: 'Body Font Name',  
        // mono: 'system-ui',  
    },
});

export default customTheme;